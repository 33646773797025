import { IconButton, Typography } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import useThemeStore from '../../stores/themeStore';

export default function ThemeSwitcher() {
    const mode = useTheme().palette.mode;
    const { switchMode } = useThemeStore();

    return (
      <>
        <Typography variant="p" sx={{ mr: 1 }}>Alternar tema</Typography>
        <IconButton onClick={switchMode}>
          {mode === 'dark' ? <Brightness4 /> : <Brightness7 />}
        </IconButton>
      </>
    );
};