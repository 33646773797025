import * as React from 'react';
import { Snackbar, Alert } from '@mui/material';
import useToastStore from '../../stores/toastStore';

export default function Toast() {
  const { toastVisible, toastMessage, toastType, toast } = useToastStore();  

  const handleCloseSnackBar = () => {
    toast.hide();
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
              open={toastVisible} autoHideDuration={5000} onClose={handleCloseSnackBar}>
      <Alert onClose={handleCloseSnackBar} severity={toastType} variant="filled" sx={{ width: '100%' }}>
        {toastMessage}
      </Alert>
    </Snackbar>      
  );
};