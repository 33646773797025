import PFEnvironment from "../shared/PFEnvironment";
import PFStorage from "../shared/PFStorage";

export default async function refreshTokenInterceptor(response, requestOptions) {
  if (response.status === 401 && !response.url.endsWith('/auth/login')) {
    console.info('Unauthorized! Refreshing access token');

    const newToken = await refreshAccessToken();
    if (newToken) {
      PFStorage.setItem('accessToken', newToken);
      const headers = {...requestOptions.headers, Authorization: `Bearer ${newToken}`};
      response = await fetch(response.url, { ...requestOptions, headers });
    }
  }

  return response;
}

async function refreshAccessToken() {
  const accessToken = PFStorage.getItem('accessToken');
  const refreshToken = PFStorage.getItem('refreshToken');

  const refreshTokenOptions = {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ accessToken, refreshToken })
  };

  const refreshTokenUrl = PFEnvironment.apiBaseUrl + '/auth/refreshtoken';
  const refreshTokenResponse = await fetch(refreshTokenUrl, refreshTokenOptions);

  if (refreshTokenResponse.ok) {
    console.info('Access token successfully refreshed');

    const data = await refreshTokenResponse.json();
    return data.accessToken;
  }
}