import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import { Logout, AccountCircle, Brightness4, Brightness7 } from '@mui/icons-material';
import { AppBar, Toolbar } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import useThemeStore from "../../stores/themeStore";
import UserAPI from '../../services/api/UserAPI';
import useChangePasswordDialogStore from '../../stores/changePasswordDialogStore';
import PFEnvironment from '../../services/shared/PFEnvironment';

export default function MainWorkspaceAppBar() {
  const { switchMode } = useThemeStore();
  const { showChangePasswordDialog } = useChangePasswordDialogStore();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const mode = useTheme().palette.mode;
  const socialName = UserAPI.socialName;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenChangePasswordDialog = () => {
    setAnchorEl(null);
    showChangePasswordDialog();
  };

  const handleLogout = () => {
    PFEnvironment.dispatchLoggingOutEvent(0, 'User logging out');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Personal Finance
        </Typography>

        <Typography variant="p" sx={{ mr: 1 }}>
            {socialName}
        </Typography>

        <IconButton size="large" aria-label="Conta do utilizador" aria-controls="menu-appbar" 
                    aria-haspopup="true" onClick={handleOpenMenu}>
          <AccountCircle fontSize="large" />
        </IconButton>
        
        <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
              keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu} slotProps={{paper: {sx: {width: 240}}}}>

          <MenuItem onClick={handleOpenChangePasswordDialog}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText>Alterar Password</ListItemText>
          </MenuItem>

          <MenuItem onClick={switchMode}>
            <ListItemIcon>
              {mode === 'dark' ? <Brightness4 /> : <Brightness7 />}
            </ListItemIcon>
            <ListItemText>Alternar Tema</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Fechar Sessão</ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>    
    </AppBar>
  );
}