import * as React from 'react';
import { Backdrop, CircularProgress, Modal } from '@mui/material';
import useBusyIndicatorStore from '../../stores/busyIndicatorStore';

export default function BusyIndicator() {
  const { busyIndicatorVisible } = useBusyIndicatorStore();

  return (
    <Modal open={busyIndicatorVisible}>
      <Backdrop sx={{ zIndex: 99999 }} open={busyIndicatorVisible}>
        <CircularProgress color="inherit" />
      </Backdrop>    
    </Modal>
  );
};