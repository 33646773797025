import { jwtDecode } from 'jwt-decode';

export default class AccessTokenDecoder {
  constructor(accessToken) {
    this.decodedAccessToken = jwtDecode(accessToken);
  }

  get userId() {
    return this.decodedAccessToken.sub;
  }

  get socialName() {
    return this.decodedAccessToken.socialName;
  }
}