import useChangePasswordDialogStore from '../../stores/changePasswordDialogStore';
import ChangePasswordDialog from './ChangePasswordDialog';
import MainWorkspaceAppBar from './MainWorkspaceAppBar';

export default function MainWorkspace() {
  const { isChangePasswordDialogOpen } = useChangePasswordDialogStore();

  return (
    <>
      <MainWorkspaceAppBar />

      {isChangePasswordDialogOpen && <ChangePasswordDialog />}
    </>
  )
};