export default class PFEnvironment {
  static get apiBaseUrl() {
    return process.env.REACT_APP_PERSONALFINANCE_API_URL;
  }

  static dispatchSystemErrorEvent(message) {
    const errorEvent = new CustomEvent('systemErrorEvent', { detail: { message: message } });
    document.dispatchEvent(errorEvent);
  }

  static dispatchLoggingOutEvent(reasonId, reasonText) {
    const logginOutEvent = new CustomEvent('loggingOutEvent', { detail: { reasonId, reasonText } });
    document.dispatchEvent(logginOutEvent);
  }
}
