import PFEnvironment from "../shared/PFEnvironment";

export default class PFFetchBase {
  static #afterInterceptors = [];
  static #beforeInterceptors = [];

  static async fetch(url, options = {}) {
    for(const interceptor of this.#beforeInterceptors) {
      options = await interceptor(options);
      console.info(`Executed Before Interceptor: ${interceptor.name}`);
    };
      
    const fetchUrl = PFEnvironment.apiBaseUrl + url;
    let response = await fetch(fetchUrl, options);
    console.info('Executed regular fetch');

    for(const interceptor of this.#afterInterceptors) {
      response = await interceptor(response, options);
      console.info(`Executed After Interceptor: ${interceptor.name}`);
    };
    
    return response;
  }

  static addBeforeInterceptor(interceptor) {
    this.#beforeInterceptors.push(interceptor);
  }

  static addAfterInterceptor(interceptor) {
    this.#afterInterceptors.push(interceptor);
  }

  static clearAllInterceptors() {
    this.#beforeInterceptors.length = 0;
    this.#afterInterceptors.length = 0;
  }
}