import { create } from 'zustand'

const useToastStore = create((set) => ({
  toastVisible: false,
  toastMessage: '',
  toastType: 'info',
  toast: {
    showSuccess: (newMessage) => set({ toastVisible: true, toastMessage: newMessage, toastType: 'success' }),
    showInfo: (newMessage) => set({ toastVisible: true, toastMessage: newMessage, toastType: 'info' }),
    showWarning: (newMessage) => set({ toastVisible: true, toastMessage: newMessage, toastType: 'warning' }),
    showError: (newMessage) => set({ toastVisible: true, toastMessage: newMessage, toastType: 'error' }),
    hide: () => set({ toastVisible: false })
  }
}));

export default useToastStore;