import Stack from '@mui/material/Stack';
import LoginBox from "./LoginBox";
import ThemeSwitcher from "./ThemeSwitcher";

export default function AuthWorkspace() {
  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }} >
        <ThemeSwitcher />
      </Stack>
      
      <LoginBox />
    </>
  )
};