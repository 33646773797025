import { create } from 'zustand'

const useBusyIndicatorStore = create((set) => ({
  busyIndicatorVisible: false,
  busyIndicator: {
    show: () => set({ busyIndicatorVisible: true }),
    hide: () => set({ busyIndicatorVisible: false })
  }
}));

export default useBusyIndicatorStore;