import * as React from 'react';
import { Stack } from '@mui/material';
import { Button, TextField } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import UserAPI from '../../services/api/UserAPI';
import useToastStore from '../../stores/toastStore';
import useBusyIndicatorStore from '../../stores/busyIndicatorStore';
import useChangePasswordDialogStore from '../../stores/changePasswordDialogStore';
import PFEnvironment from '../../services/shared/PFEnvironment';

export default function ChangePasswordDialog() {
  const localizedMessages = [ 
    'Password atualizada com sucesso', 
    'A nova Password e a sua confirmação não coincidem', 
    'A Password não está correta' ];

  const { isChangePasswordDialogOpen, hideChangePasswordDialog } = useChangePasswordDialogStore();
  const { busyIndicator } = useBusyIndicatorStore();
  const { toast } = useToastStore();

  const handleSubmit = async (event) => {
    try {
      busyIndicator.show();

      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const changePasswordDto = Object.fromEntries(formData.entries());

      const result = await UserAPI.changePassword(changePasswordDto);

      busyIndicator.hide();

      if (result.status === 'Success') {
        toast.showSuccess(localizedMessages[0]);
        handleClose();
      } else if (result.status === 'Unauthorized') {
        PFEnvironment.dispatchLoggingOutEvent(1, 'Invalid/expired access or refresh tokens');
        handleClose();
      } else if (result.status === 'BadRequest') {
        toast.showWarning(localizedMessages[result.errorCode]);
      }
    } catch (error) {
      busyIndicator.hide();
      PFEnvironment.dispatchSystemErrorEvent(error.message);      
    }
  };

  const handleClose = () => {
    hideChangePasswordDialog();
  };

  return (
    <Dialog disableRestoreFocus component="form"
            open={isChangePasswordDialogOpen} onClose={handleClose} onSubmit={handleSubmit}>
      <DialogTitle>Alterar Password</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          Insira a password atual e defina a nova password.
        </DialogContentText>
        <Stack spacing={4} direction="column" alignItems="left" sx={{ width: '75%' }}>
          <TextField autoFocus required margin="normal" id="currentPassword" name="currentPassword"
            label="Password atual" type="password" variant="standard" />
          <TextField required margin="normal" id="newPassword" name="newPassword"
            label="Nova password" type="password" variant="standard" />
          <TextField required margin="normal" id="newPasswordConfirmation" name="newPasswordConfirmation"
            label="Nova password - Confirmação" type="password" variant="standard" />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
        <Button variant="outlined" type="submit">Alterar</Button>
      </DialogActions>
    </Dialog>
  );
}