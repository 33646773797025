import PFStorage from "../shared/PFStorage";

export default async function addAccessTokenToHeaderInterceptor(options) {
  let headers = options.headers || {};

  let token = PFStorage.getItem('accessToken');
  if (token) {
    headers = {...headers, Authorization: `Bearer ${token}`};
  }

  const result = { ...options, headers };

  return result;
}