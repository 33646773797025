import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { TextField, Button, Typography } from '@mui/material';
import UserAPI from '../../services/api/UserAPI';
import useWorkspaceStore from '../../stores/workspaceStore';
import useBusyIndicatorStore from '../../stores/busyIndicatorStore';
import useToastStore from '../../stores/toastStore';
import PFEnvironment from '../../services/shared/PFEnvironment';

export default function LoginBox() {
  const { busyIndicator } = useBusyIndicatorStore();
  const { toast } = useToastStore();
  const { activateMainWorkspace } = useWorkspaceStore();
  
  const handleSubmit = async (event) => {
    try {
      busyIndicator.show();

      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const credentials = Object.fromEntries(formData.entries());

      const authResult = await UserAPI.login(credentials);

      busyIndicator.hide();

      if (authResult.success === true) {
        activateMainWorkspace();
      } else {
        toast.showWarning('Autenticação falhada');
      }
    } catch (error) {
      busyIndicator.hide();
      PFEnvironment.dispatchSystemErrorEvent(error.message);      
    }
  };

  return (
    <>
      <Box className="login-box" component="form" onSubmit={handleSubmit}>
        <Stack spacing={4} direction="column" alignItems="center">
          <Typography variant="h5" sx={{ mt: 10 }}>Personal Finance</Typography>
          <TextField autoFocus required name="userId" label="Utilizador" variant="standard" />
          <TextField required name="password" label="Password" variant="standard" type="password" />
          <Button type="submit" variant="outlined">Entrar</Button>
        </Stack>
      </Box>
    </>
  )
};
